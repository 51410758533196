(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mobx"), require("React"), require("sbInternalMsgBus"), require("mobxReact"), require("sbJsExtends"), require("sbRespBlockLib"), require("sbDataLayer"), require("ReactDOM"), require("gsap"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-filter-block", ["mobx", "React", "sbInternalMsgBus", "mobxReact", "sbJsExtends", "sbRespBlockLib", "sbDataLayer", "ReactDOM", "gsap"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-filter-block"] = factory(require("mobx"), require("React"), require("sbInternalMsgBus"), require("mobxReact"), require("sbJsExtends"), require("sbRespBlockLib"), require("sbDataLayer"), require("ReactDOM"), require("gsap"));
	else
		root["sb-responsive-filter-block"] = factory(root["mobx"], root["React"], root["sbInternalMsgBus"], root["mobxReact"], root["sbJsExtends"], root["sbRespBlockLib"], root["sbDataLayer"], root["ReactDOM"], root["gsap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__99__, __WEBPACK_EXTERNAL_MODULE__100__) {
return 